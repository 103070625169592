import React, {Component} from 'react';
import Quiz from './Quiz/Quiz'
import './App.css';

class App extends Component {
    render() {
        return (
            <div>
                <Quiz/>
            </div>
        );
    }
}

export default App;
