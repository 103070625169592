import React, {Fragment, Component} from 'react';
import axios from 'axios';
import './Quiz.css';

class Quiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: {name: '', email: '', phone: '', userAnswer: {}},
            temp: '',
            counter: 0,
            quizs: {},
            isDisabled: true
        };
    }

    componentDidMount() {
        document.title = "Опросник";
        axios.get('https://api.myjson.com/bins/1frvw6')
            .then(response => {
                this.setState({quizs: response.data.questions});
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    handleInputChange = event => {
        this.setState({temp: event.target.value, isDisabled: false});
    };

    incrementCounter = () => this.setState({counter: this.state.counter + 1});

    nextQuestion = () => {
        let a = this.state.answers;
        a.userAnswer[this.state.quizs[this.state.counter].title] = this.state.temp;
        this.setState({answers: a, isDisabled: true});
        this.incrementCounter();
    };

    submitAllData = (e) => {
        e.preventDefault();
        let body = this.state.answers;
        axios.post(`order.php`, {body})
            .then(res => {
                console.log(res);
            });
        window.location.href = "http://google.com";
    };
    handleFormChange = (e) => {
        let a = this.state.answers;
        a[e.target.name] = e.target.value;
        this.setState({answers: a});
    };


    render() {
        let counter = this.state.counter;
        let quizs = this.state.quizs;
        let quiz = quizs[counter];
        let classNames = `quiz1 myclass_${counter}`;
        return (
            <Fragment>
                {(counter < quizs.length && counter > -1) ?
                    <section className={classNames}>
                        <div className="quiz_box" key={quiz.id}>
                            <div className="quiz_title">{quiz.title}</div>
                            <div className="quiz_title_line"/>
                            <div className="quiz_text">{quiz.text}
                                <span className="quiz_text_bold">{quiz.text_span}</span>
                            </div>
                            {quiz.answers.map(q => (
                                <label className="quiz_form" key={q.value}>
                                    <div className="quiz_input_text">{q.value}</div>
                                    <input type="radio" name={quiz.name} value={q.value}
                                           onChange={this.handleInputChange}/>
                                    <div className="checkmark"/>
                                </label>
                            ))}
                            <div className="quiz_btn_box">
                                <button className="main_btn" disabled={this.state.isDisabled}
                                        onClick={this.nextQuestion}>
                                    {counter < quizs.length - 1 ?
                                        "Следующий вопрос" : "Завершить тест"}
                                </button>
                            </div>
                        </div>
                    </section>
                    : ''}
                {counter === quizs.length ?
                    <section className="quiz1 quiz_fin">
                        <div className="quiz_box">
                            <div className="quiz_title">Заполните форму, чтобы получить результаты <br/>теста и
                                бонус!
                            </div>
                            <div className="quiz_title_line"/>
                            <div className="quiz_text">Получите в качестве бонуса годичный план по
                                инвестированию на 2019 год полностью бесплатно.
                            </div>
                            <form className="form_fin" action="#" method="post">
                                <input type="text" name="name" required className="fin_input"
                                       placeholder="Ваше имя" value={this.state.answers.name}
                                       onChange={this.handleFormChange}/>
                                <input type="text" name="phone" required className="fin_input"
                                       placeholder="Ваш телефон" value={this.state.answers.phone}
                                       onChange={this.handleFormChange}/>
                                <input type="text" name="email" required className="fin_input"
                                       placeholder="Ваша почта" value={this.state.answers.email}
                                       onChange={this.handleFormChange}/>
                                <button className="main_btn" onClick={this.submitAllData}>Получить бонус
                                </button>
                            </form>
                            <div className="form_text">Все ваши данные не будут переданы третьим лицам</div>
                        </div>
                    </section> : ''}
            </Fragment>
        )
    }
}

export default Quiz
